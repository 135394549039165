const PRODUCT_ACTION_TYPES = {
  RESET_PRODUCT_REDUCER: "product/RESET_PRODUCT_REDUCER",

  SET_SELECTED_PRODUCTS: "product/SET_SELECTED_PRODUCTS",
  SET_IS_SELECTED_EXPORT: "product/SET_IS_SELECTED_EXPORT",
  SET_IS_SELECTED_DELETE: "product/SET_IS_SELECTED_DELETE",

  SET_EDIT_MODIFIER_INDEX: "product/SET_EDIT_MODIFIER_INDEX",
  SET_EDIT_MODIFIER_MODEL: "product/SET_EDIT_MODIFIER_MODEL",

  SET_PRODUCTS: "product/SET_PRODUCTS",
  SET_ORDER_PRODUCTS: "product/SET_ORDER_PRODUCTS",
  SET_PRODUCT: "product/SET_PRODUCT",

  SET_IS_PRODUCTS_HAS_MORE: "product/SET_IS_PRODUCTS_HAS_MORE",
  SET_IS_ORDER_PRODUCTS_HAS_MORE: "product/SET_IS_ORDER_PRODUCTS_HAS_MORE",

  SET_FETCH_PRODUCTS_SEARCH: "product/SET_FETCH_PRODUCTS_SEARCH",
  SET_FETCH_PRODUCTS_SORT: "product/SET_FETCH_PRODUCTS_SORT",
  SET_FETCH_PRODUCTS_KEY_BY: "product/SET_FETCH_PRODUCTS_KEY_BY",
  SET_FETCH_PRODUCTS_PAGE: "product/SET_FETCH_PRODUCTS_PAGE",
  SET_FETCH_PRODUCTS_PER_PAGE: "product/SET_FETCH_PRODUCTS_PER_PAGE",
  SET_FETCH_PRODUCTS_INCLUDES: "product/SET_FETCH_PRODUCTS_INCLUDES",
  SET_FETCH_PRODUCTS_FILTER_MARKET_ID:
    "product/SET_FETCH_PRODUCTS_FILTER_MARKET_ID",
  SET_FETCH_PRODUCTS_FILTER_BRANCH_ID:
    "product/SET_FETCH_PRODUCTS_FILTER_BRANCH_ID",
  SET_FETCH_PRODUCTS_FILTER_PRODUCT_CATEGORY_ID:
    "product/SET_FETCH_PRODUCTS_FILTER_PRODUCT_CATEGORY_ID",
  SET_FETCH_PRODUCTS_FILTER_PRODUCTS_IDS:
    "product/SET_FETCH_PRODUCTS_FILTER_PRODUCTS_IDS",
  SET_FETCH_PRODUCTS_FILTER_IS_ACTIVE:
    "product/SET_FETCH_PRODUCTS_FILTER_IS_ACTIVE",
  SET_FETCH_PRODUCTS_LOADING: "product/SET_FETCH_PRODUCTS_LOADING",
  SET_FETCH_PRODUCTS_SUCCESS: "product/SET_FETCH_PRODUCTS_SUCCESS",
  SET_FETCH_PRODUCTS_FAILED: "product/SET_FETCH_PRODUCTS_FAILED",

  SET_FETCH_ORDER_PRODUCTS_SEARCH: "product/SET_FETCH_ORDER_PRODUCTS_SEARCH",
  SET_FETCH_ORDER_PRODUCTS_SORT: "product/SET_FETCH_ORDER_PRODUCTS_SORT",
  SET_FETCH_ORDER_PRODUCTS_KEY_BY: "product/SET_FETCH_ORDER_PRODUCTS_KEY_BY",
  SET_FETCH_ORDER_PRODUCTS_PAGE: "product/SET_FETCH_ORDER_PRODUCTS_PAGE",
  SET_FETCH_ORDER_PRODUCTS_PER_PAGE:
    "product/SET_FETCH_ORDER_PRODUCTS_PER_PAGE",
  SET_FETCH_ORDER_PRODUCTS_INCLUDES:
    "product/SET_FETCH_ORDER_PRODUCTS_INCLUDES",
  SET_FETCH_ORDER_PRODUCTS_FILTER_MARKET_ID:
    "product/SET_FETCH_ORDER_PRODUCTS_FILTER_MARKET_ID",
  SET_FETCH_ORDER_PRODUCTS_FILTER_BRANCH_ID:
    "product/SET_FETCH_ORDER_PRODUCTS_FILTER_BRANCH_ID",
  SET_FETCH_ORDER_PRODUCTS_FILTER_PRODUCT_CATEGORY_ID:
    "product/SET_FETCH_ORDER_PRODUCTS_FILTER_PRODUCT_CATEGORY_ID",
  SET_FETCH_ORDER_PRODUCTS_FILTER_PRODUCTS_IDS:
    "product/SET_FETCH_ORDER_PRODUCTS_FILTER_PRODUCTS_IDS",
  SET_FETCH_ORDER_PRODUCTS_FILTER_IS_ACTIVE:
    "product/SET_FETCH_ORDER_PRODUCTS_FILTER_IS_ACTIVE",
  SET_FETCH_ORDER_PRODUCTS_LOADING: "product/SET_FETCH_ORDER_PRODUCTS_LOADING",
  SET_FETCH_ORDER_PRODUCTS_SUCCESS: "product/SET_FETCH_ORDER_PRODUCTS_SUCCESS",
  SET_FETCH_ORDER_PRODUCTS_FAILED: "product/SET_FETCH_ORDER_PRODUCTS_FAILED",

  SET_FETCH_PRODUCT_LOADING: "product/SET_FETCH_PRODUCT_LOADING",
  SET_FETCH_PRODUCT_SUCCESS: "product/SET_FETCH_PRODUCT_SUCCESS",
  SET_FETCH_PRODUCT_FAILED: "product/SET_FETCH_PRODUCT_FAILED",

  SET_CREATE_PRODUCT_LOADING: "product/SET_CREATE_PRODUCT_LOADING",
  SET_CREATE_PRODUCT_SUCCESS: "product/SET_CREATE_PRODUCT_SUCCESS",
  SET_CREATE_PRODUCT_FAILED: "product/SET_CREATE_PRODUCT_FAILED",

  SET_UPDATE_PRODUCT_LOADING: "product/SET_UPDATE_PRODUCT_LOADING",
  SET_UPDATE_PRODUCT_SUCCESS: "product/SET_UPDATE_PRODUCT_SUCCESS",
  SET_UPDATE_PRODUCT_FAILED: "product/SET_UPDATE_PRODUCT_FAILED",

  SET_DELETE_PRODUCT_LOADING: "product/SET_DELETE_PRODUCT_LOADING",
  SET_DELETE_PRODUCT_SUCCESS: "product/SET_DELETE_PRODUCT_SUCCESS",
  SET_DELETE_PRODUCT_FAILED: "product/SET_DELETE_PRODUCT_FAILED",

  SET_MULTIPLE_CREATE_PRODUCTS_LOADING:
    "product/SET_MULTIPLE_CREATE_PRODUCTS_LOADING",
  SET_MULTIPLE_CREATE_PRODUCTS_SUCCESS:
    "product/SET_MULTIPLE_CREATE_PRODUCTS_SUCCESS",
  SET_MULTIPLE_CREATE_PRODUCTS_FAILED:
    "product/SET_MULTIPLE_CREATE_PRODUCTS_FAILED",

  SET_MULTIPLE_DELETE_PRODUCTS_LOADING:
    "product/SET_MULTIPLE_DELETE_PRODUCTS_LOADING",
  SET_MULTIPLE_DELETE_PRODUCTS_SUCCESS:
    "product/SET_MULTIPLE_DELETE_PRODUCTS_SUCCESS",
  SET_MULTIPLE_DELETE_PRODUCTS_FAILED:
    "product/SET_MULTIPLE_DELETE_PRODUCTS_FAILED",

  APPEND_PRODUCTS: "product/APPEND_PRODUCTS",
  APPEND_ORDER_PRODUCTS: "product/APPEND_ORDER_PRODUCTS",

  IS_FETCH_PRODUCTS_HITTED: "product/IS_FETCH_PRODUCTS_HITTED",
  IS_FETCH_ORDER_PRODUCTS_HITTED: "product/IS_FETCH_ORDER_PRODUCTS_HITTED",
  IS_FETCH_PRODUCT_HITTED: "product/IS_FETCH_PRODUCT_HITTED",
  IS_CREATE_PRODUCT_HITTED: "product/IS_CREATE_PRODUCT_HITTED",
  IS_UPDATE_PRODUCT_HITTED: "product/IS_UPDATE_PRODUCT_HITTED",
  IS_DELETE_PRODUCT_HITTED: "product/IS_DELETE_PRODUCT_HITTED",
  IS_MULTIPLE_CREATE_PRODUCTS_HITTED:
    "product/IS_MULTIPLE_CREATE_PRODUCTS_HITTED",
  IS_MULTIPLE_DELETE_PRODUCTS_HITTED:
    "product/IS_MULTIPLE_DELETE_PRODUCTS_HITTED",

  FETCH_PRODUCTS_START: "product/FETCH_PRODUCTS_START",
  FETCH_ORDER_PRODUCTS_START: "product/FETCH_ORDER_PRODUCTS_START",
  FETCH_PRODUCT_START: "product/FETCH_PRODUCT_START",
  CREATE_PRODUCT_START: "product/CREATE_PRODUCT_START",
  UPDATE_PRODUCT_START: "product/UPDATE_PRODUCT_START",
  DELETE_PRODUCT_START: "product/DELETE_PRODUCT_START",
  MULTIPLE_CREATE_PRODUCTS_START: "product/MULTIPLE_CREATE_PRODUCTS_START",
  MULTIPLE_DELETE_PRODUCTS_START: "product/MULTIPLE_DELETE_PRODUCTS_START",
};

export default PRODUCT_ACTION_TYPES;
