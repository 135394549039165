import { all, call } from "redux-saga/effects";

import { adminSaga } from "./admin/admin.saga";
import { advertisementSaga } from "./advertisement/advertisement.saga";
import { announcementSaga } from "./announcement/announcement.saga";
import { applicationSaga } from "./application/application.saga";
import { attendanceSaga } from "./attendance/attendance.saga";
import { authenticationSaga } from "./authentication/authentication.saga";
import { branchSaga } from "./branch/branch.saga";
import { cardSaga } from "./card/card.saga";
import { countrySaga } from "./country/country.saga";
import { customerSaga } from "./customer/customer.saga";
import { dashboardSaga } from "./dashboard/dashboard.saga";
import { dashboardAdminSaga } from "./dashboard-admin/dashboard-admin.saga";
import { dashboardMerchantSaga } from "./dashboard-merchant/dashboard-merchant.saga";
import { dashboardSubscriberSaga } from "./dashboard-subscriber/dashboard-subscriber.saga";
import { discountSaga } from "./discount/discount.saga";
import { driverSaga } from "./driver/driver.saga";
import { eventSaga } from "./event/event.saga";
import { exportSaga } from "./export/export.saga";
import { exportExcelSaga } from "./export-excel/export-excel.saga";
import { exportPdfSaga } from "./export-pdf/export-pdf.saga";
import { extendPeriodSaga } from "./extend-period/extend-period.saga";
import { gatewaySaga } from "./gateway/gateway.saga";
import { globalSaga } from "./global/global.saga";
import { headerBranchSaga } from "./header-branch/header-branch.saga";
import { ingredientSaga } from "./ingredient/ingredient.saga";
import { ingredientHistorySaga } from "./ingredient-history/ingredient-history.saga";
import { integrationSaga } from "./integration/integration.saga";
import { kitchenSaga } from "./kitchen/kitchen.saga";
import { kitchenGroupSaga } from "./kitchen-group/kitchen-group.saga";
import { marketSaga } from "./market/market.saga";
import { merchantSaga } from "./merchant/merchant.saga";
import { msegatSaga } from "./msegat/msegat.saga";
import { orderSaga } from "./order/order.saga";
import { orderProductSaga } from "./order-product/order-product.saga";
import { paymentDeviceSaga } from "./payment-device/payment-device.saga";
import { paymentMethodSaga } from "./payment-method/payment-method.saga";
import { paymentMethodSettingSaga } from "./payment-method-setting/payment-method-setting.saga";
import { permissionAdminSaga } from "./permission-admin/permission-admin.saga";
import { permissionMerchantSaga } from "./permission-merchant/permission-merchant.saga";
import { permissionSubscriberSaga } from "./permission-subscriber/permission-subscriber.saga";
import { pdfSaga } from "./pdf/pdf.saga";
import { printerSaga } from "./printer/printer.saga";
import { printerTypeSaga } from "./printer-type/printer-type.saga";
import { productCategorySaga } from "./product-category/product-category.saga";
import { productCategorySortSaga } from "./product-category-sort/product-category-sort.saga";
import { productModifierSaga } from "./product-modifier/product-modifier.saga";
import { productSaga } from "./product/product.saga";
import { purchaseSaga } from "./purchase/purchase.saga";
import { recipeSaga } from "./recipe/recipe.saga";
import { reportSaga } from "./report/report.saga";
import { reportMoreSaga } from "./report-more/report-more.saga";
import { restockRequestSaga } from "./restock-request/restock-request.saga";
import { scheduleSaga } from "./schedule/schedule.saga";
import { sectorSaga } from "./sector/sector.saga";
import { sectorCategorySaga } from "./sector-category/sector-category.saga";
import { selectBranchSaga } from "./select-branch/select-branch.saga";
import { selectCashierSaga } from "./select-cashier/select-cashier.saga";
import { selectCountrySaga } from "./select-country/select-country.saga";
import { selectCustomerSaga } from "./select-customer/select-customer.saga";
import { selectIngredientSaga } from "./select-ingredient/select-ingredient.saga";
import { selectMarketSaga } from "./select-market/select-market.saga";
import { selectProductSaga } from "./select-product/select-product.saga";
import { selectProductCategorySaga } from "./select-product-category/select-product-category.saga";
import { settingSaga } from "./setting/setting.saga";
import { shortUrlSaga } from "./short-url/short-url.saga";
import { subscriberSaga } from "./subscriber/subscriber.saga";
import { subscriptionSaga } from "./subscription/subscription.saga";
import { summarySaga } from "./summary/summary.saga";
import { supplierSaga } from "./supplier/supplier.saga";
import { tableSaga } from "./table/table.saga";
import { taxSaga } from "./tax/tax.saga";
import { termConditionSaga } from "./term-condition/term-condition.saga";
import { topUpSaga } from "./top-up/top-up.saga";
import { userAdminSaga } from "./user-admin/user-admin.saga";
import { userCustomerSaga } from "./user-customer/user-customer.saga";
import { userDriverSaga } from "./user-driver/user-driver.saga";
import { userMerchantSaga } from "./user-merchant/user-merchant.saga";
import { userSubscriberSaga } from "./user-subscriber/user-subscriber.saga";
import { userSupplierSaga } from "./user-supplier/user-supplier.saga";
import { verificationSaga } from "./verification/verification.saga";
import { wathqSaga } from "./wathq/wathq.saga";
import { whatsappSaga } from "./whatsapp/whatsapp.saga";

export function* rootSaga() {
  yield all([
    call(adminSaga),
    call(advertisementSaga),
    call(announcementSaga),
    call(applicationSaga),
    call(attendanceSaga),
    call(authenticationSaga),
    call(branchSaga),
    call(cardSaga),
    call(countrySaga),
    call(customerSaga),
    call(dashboardSaga),
    call(dashboardAdminSaga),
    call(dashboardMerchantSaga),
    call(dashboardSubscriberSaga),
    call(discountSaga),
    call(driverSaga),
    call(eventSaga),
    call(exportSaga),
    call(exportExcelSaga),
    call(exportPdfSaga),
    call(extendPeriodSaga),
    call(gatewaySaga),
    call(globalSaga),
    call(headerBranchSaga),
    call(ingredientSaga),
    call(ingredientHistorySaga),
    call(integrationSaga),
    call(kitchenSaga),
    call(kitchenGroupSaga),
    call(marketSaga),
    call(merchantSaga),
    call(msegatSaga),
    call(orderSaga),
    call(orderProductSaga),
    call(paymentDeviceSaga),
    call(paymentMethodSaga),
    call(paymentMethodSettingSaga),
    call(permissionAdminSaga),
    call(permissionMerchantSaga),
    call(permissionSubscriberSaga),
    call(pdfSaga),
    call(printerSaga),
    call(printerTypeSaga),
    call(productCategorySaga),
    call(productCategorySortSaga),
    call(productModifierSaga),
    call(productSaga),
    call(purchaseSaga),
    call(recipeSaga),
    call(reportSaga),
    call(reportMoreSaga),
    call(restockRequestSaga),
    call(scheduleSaga),
    call(sectorSaga),
    call(sectorCategorySaga),
    call(selectBranchSaga),
    call(selectCashierSaga),
    call(selectCountrySaga),
    call(selectCustomerSaga),
    call(selectIngredientSaga),
    call(selectMarketSaga),
    call(selectProductSaga),
    call(selectProductCategorySaga),
    call(settingSaga),
    call(shortUrlSaga),
    call(subscriberSaga),
    call(subscriptionSaga),
    call(summarySaga),
    call(supplierSaga),
    call(tableSaga),
    call(taxSaga),
    call(termConditionSaga),
    call(topUpSaga),
    call(userAdminSaga),
    call(userCustomerSaga),
    call(userDriverSaga),
    call(userMerchantSaga),
    call(userSubscriberSaga),
    call(userSupplierSaga),
    call(verificationSaga),
    call(wathqSaga),
    call(whatsappSaga),
  ]);
}
